body{
    overscroll-behavior: none;
}

/* Logo */
.logo-container {
    width: 100px;
}

.logo-container > img {
    max-width: 100%;
}

.payment-block {
    min-height: 250px;
}